<script>
import Page1 from './demo.vue'
import Page2 from './demo.vue'

export default {
    components:{Page1,Page2},
    data(){
        return {
            component:this.$route.query.id?'Page1':'Page2'
        }
    }
}

</script>

<template>
    <div class="demo">
    
      <keep-alive>
        <component :is="component" include="Page1"></component>
      </keep-alive>
    </div>
  </template>

